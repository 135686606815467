import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import Layout from '../../components/Layout';

export default function CookiePolicy() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Layout>
      <Container maxWidth="lg">
        <div
          name="termly-embed"
          data-id="e3883b85-83c3-40c3-a2fb-21e8ebc53d58"
          data-type="iframe"
        />
      </Container>
    </Layout>
  );
}
